import {
  IForm,
  IFormSuccessModal,
  IModalProps,
  PricingForm,
} from "interfaces/modal";
import Image from "next/image";
import {Fragment, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {LendsqrBtn} from "../button/Button";
import {useContactLender, useContactPricing} from "../../../queries/contact";

interface QRModalProps {
  showDownloadModal?: boolean;
  setShowDownloadModal: (showDownloadModal: boolean) => void;
}

const FormModal = ({
  loanIcon,
  loanTitle,
  setShowModal,
  iconName,
  organization,
}: IModalProps) => {
  const useContactMutation = useContactLender();

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitSuccessful},
  } = useForm<IForm>({
    defaultValues: {
      message:
        "Hello, I would like to get a loan of [amount] from you. What do I need to do next?",
    },
  });

  const submitForm = async (payload: IForm) => {
    await useContactMutation.mutateAsync({
      ...payload,
      organization,
    });
    reset();
  };

  return (
    <Fragment>
      {!isSubmitSuccessful ? (
        <div className="absolute top-0 z-10 flex items-start justify-center w-full h-screen overflow-y-scroll bg-dark-400">
          <div className="bg-white-100 rounded-[1.25rem] w-[600px] py-8 px-7 justify-center mt-24 ">
            <div
              onClick={() => setShowModal(false)}
              className="bg-blue-200 shadow-sm rounded-full cursor-pointer float-right py-[.2rem] px-[.7rem] -mt-[1.5rem] -mr-[1.2rem]"
            >
              <span className="text-sm font-semibold text-white-100">X</span>
            </div>
            <div className="flex items-start gap-4">
              {loanTitle === "Irorun" && (
                <Image
                  src="/assets/images/lenderAvatar.png"
                  width={60}
                  height={60}
                  alt=""
                />
              )}

              {loanTitle !== "Irorun" && loanIcon ? (
                <Image src={loanIcon} width={48} height={48} alt="" />
              ) : (
                <span className="px-4 py-3 bg-blue-200 rounded-full text-white-100">
                  {iconName ?? ""}
                </span>
              )}

              <div>
                <h4 className="text-xl font-bold font-satoshi">{loanTitle}</h4>
                <p className="text-dark-500 text-[.85rem] opacity-60">
                  We would like to hear from you. Leave us a message and we will
                  get back to you within 24-48 hours.
                </p>
              </div>
            </div>

            <form
              onSubmit={handleSubmit(submitForm)}
              className="mt-8 font-inter"
            >
              <div className="text-gray-600">
                <div className="relative flex mt-3">
                  <div className="absolute top-4 left-2">
                    <Image
                      src="/assets/icons/person.svg"
                      width={20}
                      height={20}
                      alt=""
                    />
                  </div>
                  <input
                    id="name"
                    className="border border-gray-600 w-full rounded-[.3125rem] pl-9 py-3 outline-none placeholder:font-normal placeholder:text-gray-600"
                    type="text"
                    placeholder="Enter your name"
                    {...register("name", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                </div>
                {errors.name?.type === "required" && (
                  <p role="alert" className="text-orange-100">
                    Name is required
                  </p>
                )}
              </div>

              <div className="grid gap-6 grid-cols md:grid-cols-2">
                <div className="mt-6 text-gray-600">
                  <div className="relative flex mt-3">
                    <div className="absolute top-4 left-2">
                      <Image
                        src="/assets/icons/email.svg"
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>
                    <input
                      className="border border-gray-600 w-full rounded-[.3125rem] pl-9 py-3 outline-none placeholder:font-normal placeholder:text-gray-600"
                      type="text"
                      placeholder="Enter your email address"
                      {...register("email", {
                        required: "Email Address is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                    />
                  </div>
                  {errors.email?.type === "required" && (
                    <p role="alert" className="text-orange-100">
                      Email is required
                    </p>
                  )}
                  {errors.email?.type === "pattern" && (
                    <p role="alert" className="text-orange-100">
                      Invalid Email Address
                    </p>
                  )}
                </div>

                <div className="text-gray-600 md:mt-6">
                  <div className="relative flex mt-3">
                    <div className="absolute top-4 left-2">
                      <Image
                        src="/assets/icons/phone.svg"
                        width={20}
                        height={20}
                        alt=""
                      />
                    </div>
                    <input
                      id="phoneNumber"
                      className="border border-gray-600 w-full rounded-[.3125rem] pl-9 py-3 outline-none placeholder:font-normal placeholder:text-gray-600"
                      type="tel"
                      placeholder="Enter your phone number"
                      {...register("phoneNumber", {
                        required: true,
                        minLength: 6,
                        maxLength: 12,
                      })}
                    />
                  </div>
                  {errors.phoneNumber?.type === "required" && (
                    <p role="alert" className="text-orange-100">
                      Phone number is required
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-6 text-gray-600">
                <div className="mt-3">
                  <textarea
                    id="message"
                    className="border border-gray-600 w-full pl-4 pt-4 rounded-[.3125rem] outline-none placeholder:font-normal placeholder:text-gray-600 resize-none"
                    placeholder="Leave a message"
                    rows={5}
                    cols={5}
                    {...register("message", {
                      required: true,
                      minLength: 1,
                    })}
                    // value={areaText}
                  />
                </div>
                {errors.message?.type === "required" && (
                  <p role="alert" className="text-orange-100">
                    Message is required
                  </p>
                )}
              </div>

              <div className="mt-10 mb-3">
                <LendsqrBtn
                  btnText={
                    useContactMutation.isLoading ? "Loading..." : "Send Message"
                  }
                  padX="px-4"
                  padY="py-[1.1rem]"
                  isBgColor="bg-blue-200"
                  loading={useContactMutation.isLoading}
                  isLenders
                />
              </div>
              <p className="text-base font-normal text-gray-600 font-mulish">
                By contacting {loanTitle}, you agree to Lendsqr’s{" "}
                <a
                  href="/terms"
                  target="_blank"
                  className="text-blue-200 underline"
                >
                  terms of service
                </a>{" "}
                and{" "}
                <a
                  href="/privacy"
                  target="_blank"
                  className="text-blue-200 underline"
                >
                  privacy policy.
                </a>
              </p>
            </form>
          </div>
        </div>
      ) : (
        <SuccessModal setShowModal={setShowModal} />
      )}

      {useContactMutation.isError && <ErrorModal />}
    </Fragment>
  );
};

const SuccessModal = ({isCta, setShowModal, message}: IFormSuccessModal) => {
  return (
    <div className="fixed top-0 z-50 flex items-start justify-center  w-full h-screen bg-dark-400 overflow-scroll">
      <div className="bg-white-100 rounded-[1.25rem] w-full md:w-[550px] py-8 px-4 md:px-10 text-center mt-24">
        <div>
          <Image
            src="/assets/icons/success.svg"
            width={80}
            height={80}
            alt=""
          />
        </div>

        <div className="mt-8 text-dark-500">
          <h4 className="font-satoshi font-black text-[1.2rem] md:text-1.5xl">
            {isCta
              ? "We’ve recieved your infromation"
              : "Your message has been successfully sent"}
          </h4>

          {message && (
            <div>
              <p className="pricing-p">{message}</p>
              <p className="hour-span pricing-blue">24-48 hours.</p>
            </div>
          )}

          {!message && (
            <p className="text-sm opacity-60 md:text-base">
              {isCta
                ? "Our team will get in touch with you in 24-48 hours"
                : "The team will get in touch with you in 24-48 hours."}
            </p>
          )}

          <div className="mt-8" onClick={() => setShowModal(false)}>
            <LendsqrBtn
              btnText="Close"
              padX="px-[1.25rem]"
              padY="py-[.9rem]"
              isBgColor="bg-blue-200"
              isLenders
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ErrorModal = () => {
  return (
    <div className="absolute top-0 z-10 flex items-start justify-center w-full h-screen bg-dark-400">
      <div className="bg-white-100 rounded-[1.25rem] w-full md:w-[600px] py-8 px-4 md:px-10 text-center mt-24">
        <div>
          <Image
            src={"/assets/icons/error.svg"}
            width={80}
            height={80}
            alt=""
          />
        </div>

        <div className="mt-8 text-dark-500">
          <h4 className="font-satoshi font-black text-[1.3rem] md:text-2xl">
            Something went wrong
          </h4>
          <p className="text-sm opacity-60 md:text-base">
            Seems like something went wrong. Try again
          </p>

          <div className="mt-8">
            <LendsqrBtn
              btnLink="/d/lenders"
              btnText="Try Again"
              padX="px-[1.25rem]"
              padY="py-[.9rem]"
              isBgColor="bg-blue-200"
              isLenders
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Sharemodal = ({
  setOpenModal,
}: {
  setOpenModal: (value: boolean) => void;
}) => {
  const [copied, setCopied] = useState(false);
  const getUrl = window.location.href;

  useEffect(() => {
    if (copied === false) return;
    setTimeout(() => setCopied(false), 2000);
  }, [copied]);

  return (
    <div className="absolute top-0 z-10 flex items-start justify-center w-full h-screen bg-dark-400">
      <div className="bg-white-100 rounded-[1.25rem] w-full md:w-[600px] py-8 px-4 md:px-10 text-center mt-24">
        <div className="mt-8 text-dark-500">
          <h4 className="font-satoshi font-black text-[1.3rem] md:text-2xl">
            Share link
          </h4>
          <p className="text-sm opacity-60 md:text-base">
            Invite your friends and family to take a loan
          </p>

          <div className="my-8">
            <div className="flex items-center justify-between border border-gray-600 rounded-[.3125rem] px-4">
              <p className="overflow-hidden text-base font-medium text-left text-gray-600 text-ellipsis whitespace-nowrap">
                {getUrl}
              </p>

              {!copied ? (
                <div
                  className="cursor-pointer"
                  onClick={async () => {
                    await navigator.clipboard.writeText(getUrl);
                    setCopied(true);
                  }}
                >
                  <Image
                    src="/assets/icons/copy.svg"
                    width={40}
                    height={70}
                    alt="Copy"
                    priority
                  />
                </div>
              ) : (
                <p className="py-5">Copied!</p>
              )}
            </div>
          </div>

          <div className="mt-8" onClick={() => setOpenModal(false)}>
            <LendsqrBtn
              btnText="Close"
              padX="px-[1.25rem]"
              padY="py-[.9rem]"
              isBgColor="bg-blue-200"
              isLenders
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackModal = ({
  setOpenModal,
}: {
  setOpenModal: (value: boolean) => void;
}) => {
  return (
    <div className="fixed top-0 z-50 flex items-start justify-center  w-full h-screen bg-dark-400 overflow-scroll ">
      <div className="bg-white-100 rounded-[1.25rem] w-full md:w-[600px] mt-8 mb-8 py-8 px-4 md:px-10 text-center">
        <div
          onClick={() => {
            setOpenModal(false);
          }}
          className="bg-blue-200 shadow-sm rounded-full cursor-pointer float-right py-[.2rem] px-[.7rem] -mt-[1.5rem] -mr-[1.2rem]"
        >
          <span className="text-sm font-semibold text-white-100">X</span>
        </div>
        <iframe
          title="Feedback Form"
          className="freshwidget-embedded-form"
          id="freshwidget-embedded-form"
          src="https://lendsqr.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Get+Support&submitTitle=Send+Feedback&submitThanks=Thanks+for+getting+in+touch+with+Lendsqr.+Someone+from+our+team+will+be+in+touch+shortly.+However%2C+you+can+email+support%40lendsqr.com+or+call+%2B234+1+700+5282+for+immediate+assistance."
          scrolling="no"
          height="600px"
          width="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

const PricingModal = ({
  setOpenModal,
  title,
  subText,
  closingText,
  plan,
}: {
  setOpenModal: (value: boolean) => void;
  title: string;
  subText: string;
  closingText: string;
  plan: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  const useContactMutation = useContactPricing();
  type OptionType =
    | "digital_lending_service"
    | "mobile_app"
    | "ussd"
    | "bnlp"
    | "lending_apis"
    | "direct_debit"
    | "black_list_apis"
    | "loan_scoring_api"
    | "others";

  const options = [
    {value: "digital_lending_service", name: "Digital Lending Service"},
    {value: "mobile_app", name: "Mobile App"},
    {value: "ussd", name: "USSD"},
    {value: "bnlp", name: "BNPL"},
    {value: "lending_apis", name: "Lending Api's"},
    {value: "direct_debit", name: "Direct Debit"},
    {value: "black_list_apis", name: "Black List Api's"},
    {value: "loan_scoring_api", name: "Loan Scoring Api"},
    {value: "others", name: "Others"},
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors, isSubmitSuccessful},
    getValues,
    setValue,
  } = useForm<PricingForm>({
    defaultValues: {},
  });

  const submitForm = async (payload: PricingForm) => {
    setLoading(true);

    await useContactMutation.mutateAsync({
      ...payload,
      plan,
    });

    reset();
    setLoading(false);
  };

  const getServiceText = (payload: PricingForm) => {
    let serviceText = "";
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (!serviceText) {
        serviceText = payload[option.value as OptionType] ? option.name : "";
        continue;
      }

      serviceText += payload[option.value as OptionType]
        ? `, ${option.name}`
        : "";
    }

    return serviceText;
  };

  return !isSubmitSuccessful ? (
    <div className="fixed top-0 z-50 flex items-start justify-center  w-full h-screen bg-dark-400">
      <div className="bg-white-100 rounded-[1.25rem] w-[90%] md:w-[550px] mt-8 mb-8 relative overflow-hidden">
        <form className="pricing-modal" onSubmit={handleSubmit(submitForm)}>
          <div className="price-modal-header pt-4 px-4 md:px-10">
            <div
              onClick={() => {
                setOpenModal(false);
              }}
              className="bg-blue-200 shadow-sm rounded-full cursor-pointer float-right py-[.2rem] px-[.7rem] -mt-[0.3rem] mr-[0rem] md:mr-[0rem]"
            >
              <span className="text-sm font-semibold text-white-100">X</span>
            </div>

            <h2 className="pricing-h2">{title}</h2>
            <p className="pricing-p">{subText}</p>
          </div>

          <div className="pricingForm px-4 md:px-10">
            <div className="inputHolder first-input">
              <label className="pricing-label">
                Name <span>*</span>
              </label>
              <input
                type="text"
                className="pricing-input"
                {...register("name", {
                  required: true,
                  minLength: 3,
                })}
              />

              {errors.name?.type === "required" && (
                <p role="alert" className="text-orange-100">
                  Name is required
                </p>
              )}
            </div>

            <div className="inputHolder">
              <label className="pricing-label">
                Email <span>*</span>{" "}
              </label>
              <input
                type="text"
                className="pricing-input"
                {...register("email", {
                  required: "Email Address is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
              />

              {errors.email?.type === "required" && (
                <p role="alert" className="text-orange-100">
                  Email is required
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p role="alert" className="text-orange-100">
                  Invalid Email Address
                </p>
              )}
            </div>

            <div className="inputHolder">
              <label className="pricing-label">
                Phone Number <span>*</span>
              </label>
              <input
                // type="tel"

                pattern="[0-9]"
                type="number"
                className="pricing-input"
                {...register("phoneNumber", {
                  required: true,
                  minLength: 6,
                  maxLength: 12,
                })}
              />

              {errors.phoneNumber?.type === "required" && (
                <p role="alert" className="text-orange-100">
                  Phone number is required
                </p>
              )}
            </div>

            <div className="inputHolder">
              <label className="pricing-label">
                What I need help with <span>*</span>
              </label>

              <div className="service-holder">
                <img
                  src={"/assets/icons/arrow-down.svg"}
                  className="service-icon"
                  alt=""
                />
                {/* <input className='pricing-input service-input' disabled  /> */}
                <div
                  onClick={() => {
                    setShowCheckbox(!showCheckbox);
                  }}
                  className="service-input"
                >
                  <input
                    type="text"
                    className="pricing-input"
                    {...register("service", {
                      required: true,
                    })}
                    value={getValues("service")}
                    disabled
                  />
                  <div className="service-background"></div>
                </div>
              </div>

              {errors.service?.type === "required" && (
                <p role="alert" className="text-orange-100">
                  What you need is required
                </p>
              )}

              {showCheckbox && (
                <div className="checkbox-parent">
                  {options.map((option) => {
                    return (
                      <div className="checkBoxHolder" key={option.value}>
                        <input
                          id={option.value}
                          type="checkbox"
                          placeholder="January"
                          {...register(option.value as OptionType, {
                            onChange: () => {
                              setValue("service", getServiceText(getValues()), {
                                shouldValidate: true,
                              });
                            },
                          })}
                          className="mr-4"
                        />

                        <label htmlFor={option.value}>{option.name}</label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="inputHolder">
              <label className="pricing-label">Additional information</label>
              <textarea
                className="pricing-input pricing-area"
                {...register("request", {})}
              ></textarea>
            </div>

            <p className="pricing-p last-form">
              Our Growth team would be in touch with you as soon as possible but
              not more than 24 business hours. But if you need an urgent help,
              please email support@lendsqr.com.{" "}
            </p>
          </div>

          <div className="price-modal-footer px-4 md:px-10">
            <button className="pricing-btn">
              {!loading ? "Send Request" : "Sending..."}
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <SuccessModal setShowModal={setOpenModal} message={closingText} />
  );
};

const MagicModal = () => {
  return <div className="magicModal"></div>;
};

const QRCodeModal = ({setShowDownloadModal}: QRModalProps) => {
  return (
    <div className="qr-modal bg-white-100 w-[30%] h-[fit-content] mx-[auto] text-primary">
      <div className="flex flex-row flex-between-center qr-bottom-border p-[1rem]">
        <p className="body-lg-bold inter-display"> Get the app</p>
        <div className="pointer" onClick={() => setShowDownloadModal(false)}>
          <Image
            src={"/assets/images/close.svg"}
            alt={"close button"}
            width={10}
            height={10}
          />
        </div>
      </div>
      <div className="p-[1rem]">
        <div className="qr-bottom-border pb-4">
          <p className="body-md-regular mb-32">
            Access your <span className="body-md-bold"> Back Office </span> at
            your fingertips. Download the app now on Google Play Store.
          </p>
          <div className="pointer">
            <a
              href={`${process.env.NEXT_PUBLIC_GOOGLE_PLAYSTORE_URL}`}
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src={"/assets/images/play-store.svg"}
                width={162}
                height={48}
              />
            </a>
          </div>
        </div>
        <div className="flex flex-row flex-between py-[1rem] mt-3">
          <div style={{width: "60%"}}>
            <p className="body-lg-bold mb-2">Scan the QR code</p>
            <p className="body-md-regular">
              You can also scan QR code with your phone to download the app.
            </p>
          </div>
          <div className="corner-border-box">
            <div className="corner-top-right"></div>
            <div className="corner-bottom-left"></div>
            <img
              src={"/assets/images/qr_code.png"}
              alt={"qr code"}
              style={{width: "100%", height: "100%"}}
              className="pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  FormModal,
  SuccessModal,
  ErrorModal,
  Sharemodal,
  FeedbackModal,
  MagicModal,
  PricingModal,
  QRCodeModal,
};
