export const Solutions = [
  {
    url: "/#origination",
    text: "Origination",
  },
  {
    url: "/#credit",
    text: "Credit Scoring",
  },
  {
    url: "/#disbursement",
    text: "Disbursements",
  },
  {
    url: "/#collections",
    text: "Collections",
  },
  {
    url: "https://karma.ng/",
    text: "Karma Blacklist",
    isExternal: true,
  },
  {
    url: "https://adjutor.io/",
    text: "APIs for integration",
    isExternal: true,
  },
];

export const Industries = [
  {
    url: "/#industries",
    text: "Commercial Banks",
  },
  {
    url: "/#industries",
    text: "Microfinance Institutions",
  },
  {
    url: "/#industries",
    text: "SME Lenders",
  },
  {
    url: "/#industries",
    text: "Fintechs",
  },
  {
    url: "/#industries",
    text: "Human Resources",
  },
];

export const Channels = [
  {
    url: "/channels#mobile",
    text: "Mobile",
  },
  {
    url: "/channels#web",
    text: "Web",
  },
  {
    url: "/channels#websdk",
    text: "Web SDK",
  },
  {
    url: "/channels#bnpl",
    text: "BNPL",
  },
  {
    url: "/channels#api",
    text: "API distribution",
  },
  {
    url: "/channels#ussd",
    text: "USSD",
  },
];

export const Resources = [
  {
    url: "https://lendsqr.freshdesk.com/support/home",
    text: "Help Center",
    isExternal: true,
  },
  {
    url: "https://lendsqr.freshdesk.com/support/solutions/folders/44001219254",
    text: "FAQs",
    isExternal: true,
  },
  {
    url: "https://app.lendsqr.com/signup",
    text: "Get started for free",
    isExternal: true,
  },
  {
    url: "https://lendsqr.com/d/lenders",
    text: "Discover our lenders",
    isExternal: true,
  },
  {
    url: "/license",
    text: "Do I need a license?",
    isExternal: false,
  },
  {
    url: "/d/offers",
    text: "Need a loan?",
    isExternal: true,
  },
];

export const Company = [
  {
    url: "/about",
    text: "About Lendsqr",
    isExternal: true,
  },
  {
    url: "https://careers.lendsqr.com/jobs",
    text: "Careers",
    isExternal: true,
  },
  {
    url: "https://adjutor.io/",
    text: "For Developers - Adjutor",
    isExternal: true,
  },
  {
    url: "/privacy",
    text: "Privacy",
    isExternal: true,
  },
  {
    url: "/terms",
    text: "Terms",
    isExternal: true,
  },
  {
    url: "/contact",
    text: "Contact",
    isExternal: true,
  },
];

export const Social = [
  {
    url: "https://www.linkedin.com/company/lendsqr",
    text: "LinkedIn",
    icon: "linkedin",
    iconWhite: "linkedin-white",
  },
  {
    url: "https://facebook.com/Lendsqr",
    text: "Facebook",
    icon: "facebook",
    iconWhite: "facebook-white",
  },
  {
    url: "https://x.com/lendsqr",
    text: "X (Twitter)",
    icon: "x",
    iconWhite: "x",
  },
  {
    url: "https://instagram.com/lendsqr",
    text: "Instagram",
    icon: "instagram",
    iconWhite: "instagram-white",
  },
  {
    url: "mailto:support@lendsqr.com",
    text: "support@lendsqr.com",
    icon: "email",
    iconWhite: "email",
  },
  {
    url: "tel:+2342017005280",
    text: "+234 201 700 5280",
    icon: "phone",
    iconWhite: "phone",
  },
];

export const LoanOffers = [
  {
    url: "/d/offers",
    text: "Get ₦5,000 for 1 month",
    isExternal: false,
    amount: "₦5,000",
    tenor: "1 month",
  },
  {
    url: "/d/offers",
    text: "Get ₦10,000 for 2 months",
    isExternal: false,
    amount: "₦10,000 ",
    tenor: "2 months",
  },
  {
    url: "/d/offers",
    text: "Get ₦50,000 for 3 months",
    isExternal: false,
    amount: "₦50,000",
    tenor: "3 months",
  },
  {
    url: "/d/offers",
    text: "Get ₦100,000 for 6 months",
    isExternal: false,
    amount: "₦100,000",
    tenor: "6 months",
  },
  {
    url: "/d/offers",
    text: "Get ₦120,000 for 6 months",
    isExternal: false,
    amount: "₦120,000",
    tenor: "6 months",
  },
  {
    url: "/d/offers",
    text: "Discover available loan offers",
    isExternal: false,
  },
];

export const Lenders = [
  {
    url: "/d/lenders#top-lenders",
    text: "Discover top lenders",
    isExternal: false,
    type: "",
  },
  {
    url: "/d/lenders",
    text: "Discover Lendsqr lenders",
    isExternal: false,
    type: "powered by lendsqr",
  },
  {
    url: "/d/lenders",
    text: "Discover other lenders",
    isExternal: false,
    type: "other lender",
  },
];

export const Providers = [
  {
    url: "/d/providers",
    text: "Discover payment providers",
    isExternal: false,
    type: "payment",
  },
  {
    url: "/d/providers",
    text: "Discover KYC providers",
    isExternal: false,
    type: "KYC",
  },
  {
    url: "/d/providers",
    text: "Discover all providers",
    isExternal: false,
    type: "",
  },
];
