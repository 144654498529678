import {IProps} from "../interfaces/page";
import {Footer} from "./Footer";
import {Header} from "./Header";
import {useEffect, useState} from "react";
import Head from "next/head";
import MagicFrame from "@components/magic/MagicFrame";
import {useAppDispatch, useAppSelector} from "hooks/useRedux";
import {
  setShowMainPriceDropdown,
  setShowPriceDropdown,
} from "state/features/magicSlice";
import {Cookie} from "@components/general/Cookie";
import ProjectXFilters from "@components/filters/ProjectXFilters";

const MainLayout = ({
  children,
  isLenders,
  title,
  description,
  navigationBackground,
  isHome,
  canonicalURL = "https://lendsqr.com",
}: IProps) => {
  const dispatch = useAppDispatch();

  const [showProducts, setShowProducts] = useState(false);
  const {showModal, allFiltersState} = useAppSelector((state) => state.magic);
  const [showCookie, setShowCookie] = useState(false);

  useEffect(() => {
    if (showModal || showProducts) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [showModal, showProducts]);

  const checkParent = (target: any) => {
    let tempTarget = target;
    let index = 0;
    while (tempTarget) {
      if (tempTarget?.id === "price-holder") {
        return;
      }

      tempTarget = tempTarget.parentNode;
      index++;
    }

    dispatch(setShowPriceDropdown(false));
    dispatch(setShowMainPriceDropdown(false));
  };

  useEffect(() => {
    const isCookie = localStorage.getItem("isCookie");
    if (!isCookie) {
      setShowCookie(true);
    }

    localStorage.setItem("isCookie", "true");
  }, []);

  return (
    <div
      className="font-inter relative"
      onClick={(e) => {
        checkParent(e.target);
      }}
      id="main"
    >
      <Head>
        <title>{title}</title>
        <meta property="description" content={description} />
        <link rel="canonical" href={canonicalURL} />
      </Head>

      {showCookie && <Cookie setShowCookie={setShowCookie} />}

      <div className={` ${showModal ? "noScroll" : ""}`}>
        <Header
          isLenders={isLenders}
          showProducts={showProducts}
          setShowProducts={setShowProducts}
          navigationBackground={navigationBackground}
          isHome={isHome}
        />

        {(showProducts || showModal || allFiltersState) && (
          <div
            className={`${
              showModal || allFiltersState ? "magic-background" : ""
            }`}
            onClick={() => {
              setShowProducts(false);
            }}
          >
            {showModal && <MagicFrame />}
            {allFiltersState && <ProjectXFilters />}
          </div>
        )}

        {children}
        <Footer isLenders={isLenders} />
      </div>
    </div>
  );
};

export {MainLayout};
