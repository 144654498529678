import {Fragment} from "react";
import Link from "next/link";
import {Company, LoanOffers, Lenders, Providers} from "../utils/footer";
import {uid} from "react-uid";
import {GetStarted} from "@components/ui/GetStarted";
import {useDispatch} from "react-redux";
import {AppDispatch} from "state/store";
import {setFilter as setProviderFilter} from "state/features/providersFilterSlice";
import {setFilter as setLenderFilter} from "state/features/lendersFilterSlice";
import {setFilter as setLoanFilter} from "state/features/filterSlice";
import {useRouter} from "next/router";

interface IFooter {
  isLenders?: boolean;
}
const Footer = ({isLenders}: IFooter) => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();

  return (
    <Fragment>
      {/* <FAQ /> */}

      <GetStarted
        title="Get started with Lendsqr"
        description="Want to see first-hand how Lendqsr can push the boundaries of your lending business? Sign up now for free and check it out, we’ll be with you every step of the way!"
        buttonText="Create your free account"
        image="/assets/images/getstarted.svg"
        url="https://app.lendsqr.com/signup"
        negativeBottom
      />

      {!isLenders ? (
        <footer id="colophon">
          <div className="footer-top uni-width">
            <div className="footer-col">
              <p className="footer-head">Solutions</p>

              <a
                href="/solutions/starter"
                target="_self"
                aria-label="Go to origination"
                rel="noreferrer"
              >
                <p className="footer-p">Start</p>
              </a>

              <a
                href="/solutions/scale"
                target="_blank"
                aria-label="Go to credit"
                rel="noreferrer"
                className="mb-32"
              >
                <p className="footer-p">Scale</p>
              </a>

              <a
                href="https://blog.lendsqr.com/how-lendsqr-helped-snapcash-bridge-the-credit-divide/"
                target="_blank"
                aria-label="Go to disbursement"
                rel="noreferrer"
              >
                <p className="footer-p">For Banks</p>
              </a>

              <a
                href="https://blog.lendsqr.com/coverdey-dey-for-you-empowering-underserved-traders-with-credit/"
                target="_blank"
                aria-label="Go to Collections"
                rel="noreferrer"
              >
                <p className="footer-p">For Fintechs</p>
              </a>

              <a
                href=""
                target="_blank"
                aria-label="Read more about our karma blacklist"
                rel="noreferrer"
              >
                <p className="footer-p">For Microfinance Institutions</p>
              </a>

              <a
                href="https://blog.lendsqr.com/234loan-is-transforming-lives-one-loan-at-a-time/"
                target="_blank"
                aria-label="Read more about our api integrations"
                rel="noreferrer"
              >
                <p className="footer-p">For SMEs</p>
              </a>

              <a
                href="https://blog.lendsqr.com/what-are-payday-loans-and-how-to-make-the-best-of-them/"
                target="_blank"
                aria-label="Read more about our api integrations"
                rel="noreferrer"
              >
                <p className="footer-p">For Human Resources</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">Products</p>

              <a
                href="https://app.lendsqr.com/signup"
                target="_blank"
                aria-label="Read more about our industries"
                rel="noreferrer"
                className="mb-32"
              >
                <p className="footer-p">How to Use</p>
              </a>
              <a
                href="/products/channels"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Channels</p>
              </a>
              <a
                href="/products/back-office"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Back Office</p>
              </a>
              <a
                href="/products/product-smanagement"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Loan Product Management</p>
              </a>
              <a
                href="/products/savings"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Savings and Investments</p>
              </a>

              <a
                href="/products/decision-model"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Decision Models</p>
              </a>

              <a
                href="/products/product-management"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Payment and Collections</p>
              </a>

              <a
                href="/comingsoon"
                target="_blank"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Security</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">Resources</p>

              <a
                href=""
                target=""
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">FAQs</p>
              </a>
              <a
                href="/pricing"
                target="_self"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Pricing</p>
              </a>
              <a
                href="https://lendsqr.freshdesk.com/support/home"
                target="_blank"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Help Center</p>
              </a>
              <a
                href="https://blog.lendsqr.com/"
                target="_blank"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Blog</p>
              </a>

              <a
                href="https://blog.lendsqr.com/category/lender-case-study/"
                target="_blank"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Case Studies</p>
              </a>
              <a
                href="/d/lenders"
                target="_self"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Discover our lenders</p>
              </a>
              <a
                href="/license"
                target="_self"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Do I need a license?</p>
              </a>

              <a
                href=""
                target="_blank"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Need a loan?</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">Company</p>

              <a
                href="/about"
                target="_self"
                aria-label="Contact our support"
                rel="noreferrer"
              >
                <p className="footer-p">About Lendsqr</p>
              </a>
              <a
                href="https://careers.lendsqr.com/jobs"
                target="_blank"
                aria-label="Sign up today"
                rel="noreferrer"
              >
                <p className="footer-p">Careers</p>
              </a>
              <a
                href="https://adjutor.io/"
                target="_blank"
                aria-label="View all our lenders"
                rel="noreferrer"
              >
                <p className="footer-p">For Developers</p>
              </a>
              <a href="" aria-label="Read our privacy policy">
                <p className="footer-p">Changelog</p>
              </a>
            </div>
          </div>

          <div className="footer-line uni-width"></div>

          <div className="footer-top uni-width">
            <div className="footer-col ">
              <p className="footer-head">Contact</p>

              <a
                href="mailto:support@lendsqr.com"
                target="_blank"
                aria-label="Go to origination"
                rel="noreferrer"
              >
                <p className="footer-p">support@lendsqr.com</p>
              </a>

              <div className="footer-media-holder">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFuniv98WdGQAAAAYsiE3tAf9Plj1GdpTool61JNwhDaTg3TukePKrr5wM3aDQxMvN41pjeUGHcq-dvf53AqAakTduem_00NfHnjxHRPlMfl7vtxOqJXsRo0F0amREfej37Lx8=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Flendsqr"
                  className="footer-anchor"
                  aria-label="View our linkeding account"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/linkedin-footer.svg"
                    alt="linkedin icon"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://web.facebook.com/Lendsqr?_rdc=1&_rdr"
                  className="footer-anchor"
                  aria-label="View our facebook page"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/facebook-footer.svg"
                    alt="facebook icon"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://www.instagram.com/lendsqr/"
                  className="footer-anchor"
                  aria-label="View our instagram page"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/instagram-footer.svg"
                    alt="instagram icon"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://twitter.com/lendsqr"
                  className="footer-anchor"
                  aria-label="View our X (twitter) account"
                  rel="noreferrer"
                >
                  <img src="/assets/icons/x-footer.svg" alt="twitter icon" />
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/@Lendsqr"
                  className="footer-anchor"
                  aria-label="View our youtube page"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/youtube-footer.svg"
                    alt="youtube icon"
                  />
                </a>
              </div>
            </div>

            <div className="footer-col">
              <p className="footer-head">United States</p>

              <a>
                <p className="footer-p">
                  2055 Limestone Rd Ste 200C, Wilmington, DE 19808 <br />
                </p>
              </a>

              <a>
                <p className="footer-p">+ 1 980 350 7035</p>
              </a>
            </div>

            <div className="footer-col ">
              <p className="footer-head">Nigeria</p>

              <a>
                <p className="footer-p">
                  4 Perez Drive, Lekki Phase 1, Lagos 106104
                </p>
              </a>

              <a>
                <p className="footer-p">+234 201 700 5280</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">United Kingdom</p>

              <a>
                <p className="footer-p">
                  17 King Edwards Road, Ruislip, London, HA4 7AE
                </p>
              </a>

              <a>
                <p className="footer-p">+44 741 862 3688</p>
              </a>
            </div>
          </div>

          <div className="footer-text uni-width">
            <div className="footer-p-text">
              <p>© 2024 Lendsqr, Inc. All rights reserved</p>

              <div>
                <a
                  href="/privacy"
                  className="mr-16 footer-p-anchor"
                  aria-label="Read about our privacy policy"
                >
                  Privacy Policy
                </a>

                <a
                  href="/terms"
                  className="footer-p-anchor"
                  aria-label="See our terms of condition"
                >
                  Terms of Use
                </a>
              </div>
            </div>

            <p>
              Lendsqr.com is NOT a lender, does not make offers for loans, and
              does not broker online loans to lenders or lending partners.
              Lendsqr.com is not a representative or agent of any lender or
              lending partner. Lendsqr.com is a lending-as-a-service cloud
              platform for licensed lenders. These disclosures are intended for
              informational purposes only and should not be construed as or
              considered legal advice.
            </p>
          </div>

          <img
            src="/assets/icons/footer-logo.svg"
            alt="footer logo"
            className="footer-logo"
          />
        </footer>
      ) : (
        <footer className="bg-blue-700 relative">
          <div className="">
            <div className="max-w-custom mx-auto w-[90%] lg:w-full">
              <div className="block lg:flex justify-between">
                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">
                    Find a loan
                  </h3>
                  <ul className="mt-6">
                    {LoanOffers.map((item) => (
                      <Fragment key={uid(item)}>
                        <li
                          className="body-sm-regular inter text-white-trans-7 mb-4 cursor-pointer"
                          onClick={() => {
                            if (item.amount && item.tenor) {
                              dispatch(
                                setLoanFilter({
                                  ["amount"]: [item.amount],
                                  url: item.url,
                                })
                              );
                              dispatch(
                                setLoanFilter({
                                  ["tenor"]: [item.tenor],
                                  url: item.url,
                                })
                              );
                            } else {
                              dispatch(setLoanFilter({["amount"]: []}));
                              dispatch(setLoanFilter({["tenor"]: []}));
                              router.push(item.url);
                            }
                          }}
                        >
                          {item.text}
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </div>
                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">
                    Find a lender
                  </h3>
                  <ul className="mt-6">
                    {Lenders.map((item) => (
                      <Fragment key={uid(item)}>
                        <li
                          className="body-sm-regular inter text-white-trans-7 mb-4 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              setLenderFilter({
                                ["lenderType"]: [item.type],
                                url: item.url,
                              })
                            );

                            if (item.type === "") {
                              router.push(item.url);
                            }
                          }}
                        >
                          {item.text}
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </div>

                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">
                    Find a provider
                  </h3>
                  <ul className="mt-6">
                    {Providers.map((item) => (
                      <Fragment key={uid(item)}>
                        <li
                          className="body-sm-regular inter text-white-trans-7 mb-4 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              setProviderFilter({
                                ["serviceType"]: [item.type],
                                url: item.url,
                              })
                            );

                            if (item.type === "") {
                              router.push(item.url);
                            }
                          }}
                        >
                          {item.text}
                        </li>
                      </Fragment>
                    ))}
                  </ul>

                  <hr className="border-200 border-white-600 my-7 opacity-20" />

                  <div className="footer-media-holder !flex-col gap-5">
                    <a
                      href=""
                      target="_blank"
                      aria-label="Read more about our channels"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <p className="text-white-trans-7 inter body-sm-regular">
                        I want to start lending
                      </p>
                    </a>
                    <a
                      href="/legal/license"
                      target="_blank"
                      aria-label="Read more about our channels"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Do I need a license?
                      </p>
                    </a>
                  </div>
                </div>

                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">Lendsqr</h3>
                  <ul className="mt-6">
                    {Company.map((item) => (
                      <Fragment key={uid(item)}>
                        {item.isExternal ? (
                          <li className="body-sm-regular inter text-white-trans-7 mb-4">
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.text}
                            </a>
                          </li>
                        ) : (
                          <li className="body-sm-regular inter text-white-trans-7 mb-4">
                            <Link href={item.url} passHref>
                              {item.text}
                            </Link>
                          </li>
                        )}
                      </Fragment>
                    ))}
                  </ul>
                  <hr className="border-200 border-white-600 my-7 opacity-20" />

                  <div className="footer-media-holder !flex-col gap-5">
                    <a
                      href="mailto:support@lendsqr.com"
                      target="_blank"
                      aria-label="Reach out to support email"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <img src="/assets/icons/email.svg" alt="email icon" />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        support@lendsqr.com
                      </p>
                    </a>
                    <a
                      href="tel:+2342017005280"
                      target="_blank"
                      aria-label="Reach out to support email"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <img src="/assets/icons/phone.svg" alt="email icon" />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        +234 201 700 5280
                      </p>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFuniv98WdGQAAAAYsiE3tAf9Plj1GdpTool61JNwhDaTg3TukePKrr5wM3aDQxMvN41pjeUGHcq-dvf53AqAakTduem_00NfHnjxHRPlMfl7vtxOqJXsRo0F0amREfej37Lx8=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Flendsqr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our linkeding account"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/linkedin-footer.svg"
                        alt="linkedin icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        LinkedIn
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://web.facebook.com/Lendsqr?_rdc=1&_rdr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our facebook page"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/facebook-footer.svg"
                        alt="facebook icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Facebook
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://www.instagram.com/lendsqr/"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our instagram page"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/instagram-footer.svg"
                        alt="instagram icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Instagram
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://twitter.com/lendsqr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our X (twitter) account"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/x-footer.svg"
                        alt="twitter icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        X (Twitter)
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://www.youtube.com/@Lendsqr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our youtube page"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/youtube-footer.svg"
                        alt="youtube icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Youtube
                      </p>
                    </a>
                  </div>
                </div>
              </div>

              <hr className="border-200 border-white-600 my-7 opacity-20" />
              <div className="flex flex-col md:flex-row md:justify-between w-full mt- gap-5">
                <p className="text-white-900 body-sm-regular inter">
                  © 2024 Lending Technologies. All rights reserved
                </p>
                <div className="flex flex-row gap-4 items-center">
                  <a
                    className="decoration-white-900 underline body-sm-regular inter text-white-900"
                    href={"/legal/privacy"}
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                  <a
                    className="decoration-white-900 underline body-sm-regular inter text-white-900"
                    href={"/legal/terms-of-use"}
                  >
                    {" "}
                    Terms of Use{" "}
                  </a>
                </div>
              </div>
              <div className="mt-10">
                <p className="text-white-900 body-sm-regular inter">
                  Lendsqr.com is NOT a lender, does not make offers for loans,
                  and does not broker online loans to lenders or lending
                  partners. Lendsqr.com is not a representative or agent of any
                  lender or lending partner. Lendsqr.com is a
                  lending-as-a-service cloud platform for licensed lenders.
                  These disclosures are intended for informational purposes only
                  and should not be construed as or considered legal advice.
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}
    </Fragment>
  );
};

export {Footer};
