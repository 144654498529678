import Link from "next/link";
import {LendsqrBtn} from "@components/ui/button/Button";
import {Fragment} from "react";
import {IHeader} from "interfaces/layout";
import {HeaderDropdown} from "./HeaderDropdown";
import {useRouter} from "next/router";

const Sidebar = ({isLenders, showProducts, handleProductClick}: IHeader) => {
  const router = useRouter();
  const currentRoute = router.pathname;

  return (
    <Fragment>
      {!isLenders ? (
        <div className="bg-white-100 w-full h-[300px] top-16 left-0  z-50 block lg:hidden absolute border-t border-white-500 transition">
          <nav className="max-w-screen-xl mx-auto px-6 md:px-4 lg:px-16 py-8">
            <nav className="w-[21.5%]">
              <ul className="font-normal text-dark-200">
                {[
                  ["Find a Loan", "/d/offers"],
                  ["Find a Lender", "/d/lenders"],
                  ["Find a Provider", "/d/providers"],
                ].map(([title, url]) => (
                  <Fragment key={title}>
                    <li
                      className={`transition duration-200 body-md-bold inter cursor-pointer hover:text-green-600 w-max pb-4 ${
                        currentRoute.includes(url) ? "text-green-600" : ""
                      }`}
                    >
                      <Link href={url} passHref>
                        {title}
                      </Link>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </nav>

            <div>
              <LendsqrBtn
                btnLink="https://app.lendsqr.com/"
                btnText="Sign in"
                padX="px-[3.4rem]"
                padY="py-[.9rem]"
                isBgColor="bg-green-100"
                isSidebar
              />
            </div>
          </nav>

          {showProducts && <HeaderDropdown />}
        </div>
      ) : (
        <div className="bg-white-100 w-screen h-screen top-16 left-0  z-50 block lg:hidden absolute transition">
          <nav
            className="relative mx-auto px-6 md:px-4 lg:px-16 py-8"
            style={{
              position: "relative",
              height: "calc(100vh - 70px)",
            }}
          >
            <nav className="w-[21.5%]">
              <ul className="">
                {[
                  ["Find a Loan", "/d/offers"],
                  ["Find a Lender", "/d/lenders"],
                  ["Find a Provider", "/d/providers"],
                ].map(([title, url]) => (
                  <Fragment key={title}>
                    <li
                      className={`transition duration-200 body-md-bold inter cursor-pointer hover:text-green-600 w-max pb-4 ${
                        currentRoute.includes(url) ? "text-green-600" : ""
                      }`}
                    >
                      <Link href={url} passHref>
                        {title}
                      </Link>
                    </li>
                  </Fragment>
                ))}
              </ul>
            </nav>

            <div className="absolute bottom-0 !p-0 !w-[90%] flex flex-col gap-3 justify-center">
              <a
                className="btn-large text-primary six-15 text-center"
                target="_blank"
                href="https://lsq.li/?link=https://app.lendsqr.com&source=top-nav"
                rel="noreferrer"
              >
                Log in
              </a>
              <a
                target="_blank"
                href="https://lsq.li/?link=https://app.lendsqr.com/signup&source=navbar"
                rel="noreferrer"
                className="btn-large btn-primary text-center"
              >
                Become a Lender
              </a>
            </div>
          </nav>
          {showProducts && <HeaderDropdown />}
        </div>
      )}
    </Fragment>
  );
};

export {Sidebar};
