type GetStartedType = {
  title: string;
  description: string;
  buttonText: string;
  image: string;
  height?: boolean;
  background?: string;
  url?: string;
  internal?: boolean;
  negativeBottom?: boolean;
};

const GetStarted = ({
  title,
  description,
  buttonText,
  image,
  height,
  background,
  url,
  internal,
  negativeBottom,
}: GetStartedType) => {
  return (
    <div
      className="get-started-holder z-[100]"
      style={{backgroundColor: background}}
    >
      <div className="get-started-inner uni-width">
        <div className="get-started-one flex-one">
          <div className="get-started-one-inner header-width">
            <p
              className={`${
                background ? "text-white" : "text-brand-blue"
              } heading-5 inter-display`}
            >
              {title}
            </p>

            <p
              className={`${
                background ? "text-white-trans-7" : "text-secondary"
              } body-lg-regular mt-16`}
            >
              {description}
            </p>

            <a
              className="btn btn-primary mt-32 body-md-bold"
              href={url}
              target={internal ? "_self" : "_blank"}
              rel="noreferrer"
            >
              {buttonText}
            </a>
          </div>
        </div>

        <div className={` ${height && "get-started-two-padding"} `}>
          <img
            src={image}
            alt="get started image"
            className={`get-started-image ${
              negativeBottom && "negative-bottom"
            } ${height && "get-started-smaller"}`}
          />
        </div>
      </div>
    </div>
  );
};

export {GetStarted};
